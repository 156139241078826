<template>
  <el-card class="box-card">
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="充值类型" prop="type">
        <el-radio-group v-model="form.type" size="small">
          <el-radio-button :label="0">定额套餐</el-radio-button>
          <el-radio-button :label="1">自定义套餐</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动名称" prop="name">
        <el-input v-model="form.name" style="width: 25%" placeholder="请输入活动名称" size="small"/>
      </el-form-item>
      <el-form-item v-show="form.type == 1" style="margin-bottom: 20px" label="现金面值" prop="cash">
        <el-input-number
            v-model="form.cash"
            :precision="2"
            placeholder="小数点后2位"
            :step="0.01"
            controls-position="right"
            size="small"
        />
      </el-form-item>
      <el-form-item v-show="form.type == 1" style="margin-bottom: 20px" label="销售价格" prop="market">
        <el-input-number
            v-model="form.market"
            :precision="2"
            placeholder="小数点后2位"
            :step="0.01"
            controls-position="right"
            size="small"
        />
      </el-form-item>
      <el-form-item v-show="form.type != 1" style="margin-bottom: 20px" label="最小金额" prop="cash">
        <el-input-number
            v-model="form.cash"
            :precision="2"
            placeholder="小数点后2位"
            :step="0.01"
            controls-position="right"
            size="small"
        />
      </el-form-item>
      <el-form-item v-show="form.type != 1" style="margin-bottom: 20px" label="折扣比例" prop="market">
        <el-input-number
            v-model="form.market"
            :precision="2"
            placeholder="小数点后2位"
            :step="0.01"
            controls-position="right"
            size="small"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="封面图" prop="icon">
        <el-button v-if="!form.icon" @click="showSelect = true">选择上传图片</el-button>
        <img
            v-else
            class="avatar"
            :src="form.icon"
            style="display: block; height: 100px"
        />
        <fileSelect :visible.sync="showSelect" append-to-body state="image" title="标题"
                    @confirm="confirmHandle"></fileSelect>
        <!--        <div>(建议上传图片尺寸：80px*80px)</div>-->
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="购买奖励" prop="award">
        <el-checkbox-group size="small" v-model="form.award">
          <el-checkbox label="0" @change="getCheckValue1">优惠券</el-checkbox>
          <el-checkbox label="1" @change="getCheckValue2">积分</el-checkbox>
          <el-checkbox label="2" @change="getCheckValue3">账户余额</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item v-show="type1" style="margin-bottom: 20px" label="优惠券" prop="">
        <el-select
            v-model="couponObj"
            clearable
            multiple
            placeholder="请选择"
            style="width: 200px"
            size="small"
            @change="getCouponNum"
            @focus="monitoringCoupon"
        >
          <el-option
              v-for="item in couponArr"
              :key="item.id"
              :disabled="couponType"
              :label="item.name"
              :value="item.id"
          />
        </el-select>
        <el-button size="small" type="primary" style="margin-left: 10px" @click="addCoupons">添加优惠券(最多三张)</el-button>
        <el-table v-loading="list_state" size="small" border :data="list" style="width: 500px" height="250">
          <el-table-column
              align="center"
              prop="name"
              label="优惠券名">
          </el-table-column>
          <el-table-column
              width="100"
              align="center"
              prop="quantity"
              label="现有余量">
          </el-table-column>
          <el-table-column align="center" label="操作" width="60">
            <template #default="{ row }">
              <el-button type="text" @click="handleDelete(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item v-show="type2" style="margin-bottom: 20px" label="返积分比例" prop="=">
        <el-tooltip class="item" effect="dark" content="比例必须为整数，实际返积分=实际消费金额X返回比例" placement="top">
          <el-input-number
              v-model="form.integral_proportion"
              :step="1"
              step-strictly
              :min="0"
              :max="100"
              controls-position="right"
              size="small"
          />
        </el-tooltip>
      </el-form-item>
      <el-form-item v-show="type3" style="margin-bottom: 20px" label="返现金比例" prop="">
        <el-tooltip placement="top">
          <div slot="content">比例必须为整数，返回的现金存进个人账户<br/>的赠送额度，不可提现，只能用于消费</div>
          <el-input-number
              v-model="form.cash_proportion"
              :step="1"
              step-strictly
              :min="0"
              :max="100"
              controls-position="right"
              size="small"
          />
        </el-tooltip>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="简单说明" prop="details">
        <el-input
            type="textarea"
            :autosize="{ minRows: 2}"
            v-model="form.details"
            style="width: 25%"
            placeholder="请输入说明"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit" size="small">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import {baseURL} from '@/config'
import fileSelect from "@/views/components/fileSelect";
import {apiGetNotPageCoupon, apiGetTopPackageInfo, apiGetUserLevelList, apiUpdateTopPackage} from "@/request/api";

export default {
  components: {fileSelect},
  props: [],
  data() {
    return {
      type1: false,
      type2: false,
      type3: false,
      couponObj: [],
      couponArr: [],
      list_state: false,
      couponType: false,
      list: [],
      levelArr: [],
      showSelect: false,
      picApi: baseURL + '/base/upload/upload-file-cos',
      form: {
        id: '', //充值套餐id
        type: 0, //充值类型
        name: '', //套餐名称
        cash: '', //现金面值
        market: '', //销售价格
        icon: '',
        award: [], //充值奖励多选逗号隔开
        discounts: [], //优惠卷id多选逗号隔开
        integral_proportion: '', //积分返还百分比
        cash_proportion: '', //现金返回百分比
        details: '', //说明
      },
      rules: {
        name: [
          {required: true, trigger: 'blur', message: '套餐名不能为空'},
        ],
        cash: [
          {required: true, trigger: 'blur', message: '面值不能为空'},
        ],
        market: [
          {required: true, trigger: 'blur', message: '价格不能为空'},
        ],
        icon: [
          {required: true, trigger: 'blur', message: '图片不能为空'},
        ],
        award: [
          {required: true, trigger: 'blur', message: '奖励类型不能为空'},
        ],
        details: [
          {required: true, trigger: 'blur', message: '说明不能为空'},
        ],
      },
    }
  },
  created() {
    this.getLevelList()
    this.getCouponList()
    if (this.$route.query.id) {
      this.getPackageInfo()
    }
  },
  methods: {
    //获取套餐详情
    getPackageInfo() {
      apiGetTopPackageInfo({id: this.$route.query.id}).then(res => {
        if (res.code == 200) {
          const tempObj = res.data
          this.form = {
            id: tempObj.id, //充值套餐id
            type: tempObj.type, //充值类型
            name: tempObj.name, //套餐名称
            cash: tempObj.cash, //现金面值
            market: tempObj.market, //销售价格
            icon: tempObj.icon,
            award: tempObj.award, //充值奖励多选逗号隔开
            discounts: tempObj.discounts, //优惠卷id多选逗号隔开
            integral_proportion: tempObj.integral_proportion, //积分返还百分比
            cash_proportion: tempObj.cash_proportion, //现金返回百分比
            details: tempObj.details, //说明
          }
          this.list = tempObj.coupon
          tempObj.award.forEach(item => {
            if (item == 1) {
              this.type2 = true
            } else if (item == 2) {
              this.type3 = true
            } else if (item == '0') {
              this.type1 = true
            }
          })
        }
      })
    },
    //监听优惠券数量
    monitoringCoupon() {
      if (this.list.length == 3) {
        this.couponType = true
        this.$message.error('最多只能选择三张优惠券')
      } else {
        this.couponType = false
      }
    },
    //监听优惠券选择
    getCouponNum(e) {
      if (e.length + this.list.length == 3) {
        // this.$message.success('')
        this.couponType = true
      } else {
        this.couponType = false
      }
    },
    getCheckValue1(e) {
      this.type1 = e
    },
    getCheckValue2(e) {
      this.type2 = e
    },
    getCheckValue3(e) {
      this.type3 = e
    },
    //获取用户等级列表(不分页)
    getLevelList() {
      apiGetUserLevelList().then(res => {
        if (res.code == 200) {
          this.levelArr = res.data
        }
      })
    },
    //获取优惠券列表-不分页
    getCouponList() {
      apiGetNotPageCoupon().then(res => {
        if (res.code == 200) {
          this.couponArr = res.data
        }
      })
    },
    //上传图片
    confirmHandle(e) {
      console.log(e)
      if (e[0].thumb) {
        this.form.icon = e[0].thumb
      }
      this.showSelect = false
    },
    //添加优惠券
    addCoupons() {
      this.couponObj.forEach(item => {
        this.couponArr.forEach(child => {
          if (item == child.id) {
            this.list.push(child)
          }
        })
      })
      const map = new Map()
      // 对id属性进行去重
      this.list = this.list.filter(key => !map.has(key.id) && map.set(key.id, 1))
      if (this.list.length < 3) {
        this.couponType = false
      }
      this.couponObj = []
    },
    //删除选中优惠券
    handleDelete(row) {
      console.log(row)
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.list.map((val, i) => {
            if (val.id == row.id) {
              this.list.splice(i, 1)
            }
          })
          this.couponType = false
        })
      }
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.list = []
      this.type1 = false
      this.type2 = false
      this.type3 = false
    },
    //提交
    submit() {
      this.form.discounts = []
      if (this.list.length > 0) {
        this.list.forEach(item => {
          this.form.discounts.push(item.id)
        })
      }
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          apiUpdateTopPackage(this.form).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.close()
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          return false
        }
      })
    },
  }
}

</script>

